import AEM from 'base/js/aem';
import { onIntersect } from 'base/js/templates/general/onIntersect.js';

class Video extends AEM.Component {
    init() {
        this.onEnter = () => {
            this.element.play();
        };

        this.onExit = () => {
            return;
        };
        if (this.element.classList.contains('video-replay-scroll')) {
            onIntersect(this.element, this.onEnter, this.onExit, false, { threshold: 0.7 });
        }
        this.addControls();
    }

    getVideoComponent(ev) {
        return ev.currentTarget.closest('[data-component="video"]');
    }
    addControls() {
        this.player = this.element;
        const that = this;
        if (this.player && this.player.controls) {
            this.player.dataset.controls = 'true';
            this.player.removeAttribute('controls');
            this.parent = this.player.parentElement;
            this.controls = this.parent.querySelector('.controls');
            this.playPauseBtn = this.controls.querySelector('.playpause');
            this.stopBtn = this.controls.querySelector('.stop');
            this.rwdBtn = this.controls.querySelector('.rwd');
            this.fwdBtn = this.controls.querySelector('.fwd');
            this.timeLabel = this.controls.querySelector('.time');
            this.progress = this.controls.querySelector('progress');
            this.overlayPlay = this.parent.querySelector('.overlay-play');

            /**
             * Pause button handler.
             */
            this.playPauseBtn.addEventListener('click', e => {
                const player = that.getVideoComponent(e).querySelector('video');
                if (player.paused) {
                    player.play();
                } else {
                    player.pause();
                }
            });
            this.overlayPlay.addEventListener('click', e => {
                const player = that.getVideoComponent(e).querySelector('video');
                if (player.paused) {
                    player.play();
                } else {
                    player.pause();
                }
            });

            this.element.addEventListener('click', e => {
                const player = e.currentTarget;
                if (player.paused) {
                    player.play();
                } else {
                    player.pause();
                }
            });

            /**
             * Pause event handler.
             */
            this.element.addEventListener('pause', e => {
                const videoComponent = that.getVideoComponent(e);
                const playPauseBtn = videoComponent.querySelector('.playpause');
                const overlayPlay = videoComponent.querySelector('.overlay-play');
                // Update text and styling of pause button.
                playPauseBtn.ariaLabel = playPauseBtn.dataset.playLabel;
                playPauseBtn.classList.remove('fa-pause');
                playPauseBtn.classList.add('fa-play');
                overlayPlay.classList.remove('visually-hidden');
                overlayPlay.ariaLabel = overlayPlay.dataset.playLabel;
            });

            /**
             * Playing event handler.
             */
            this.element.addEventListener('playing', e => {
                const videoComponent = that.getVideoComponent(e);
                const playPauseBtn = videoComponent.querySelector('.playpause');
                const overlayPlay = videoComponent.querySelector('.overlay-play');
                // Update text and styling of pause button.
                playPauseBtn.ariaLabel = playPauseBtn.dataset.pauseLabel;
                playPauseBtn.classList.remove('fa-play');
                playPauseBtn.classList.add('fa-pause');
                overlayPlay.classList.add('visually-hidden');
                overlayPlay.ariaLabel = overlayPlay.dataset.pauseLabel;
            });

            /**
             * Play event handler.
             */
            this.element.addEventListener('play', e => {
                const playPauseBtn = that.getVideoComponent(e).querySelector('.playpause');
                // Update text and styling of pause button.
                playPauseBtn.ariaLabel = playPauseBtn.dataset.pauseLabel;
                playPauseBtn.classList.remove('fa-play');
                playPauseBtn.classList.add('fa-pause');
            });

            this.stopBtn.addEventListener('click', e => {
                const player = that.getVideoComponent(e).querySelector('video');
                player.pause();
                player.currentTime = 0;
            });

            this.rwdBtn.addEventListener('click', e => {
                const player = that.getVideoComponent(e).querySelector('video');
                if (player) {
                    player.currentTime -= 3;
                }
            });

            this.fwdBtn.addEventListener('click', e => {
                const player = that.getVideoComponent(e).querySelector('video');
                player.currentTime += 3;
                if (player.currentTime >= player.duration || player.paused) {
                    player.pause();
                    player.currentTime = 0;
                }
            });

            this.parent.addEventListener('keydown', e => {
                const key = e.key;
                console.log(key);
                const player = e.currentTarget.querySelector('video');
                switch (key) {
                case 'LeftArrow':
                    player.currentTime -= 3;
                    break;
                case 'RightArrow':
                    player.currentTime += 3;
                    break;
                default:
                    return;
                }
            });

            this.progress.addEventListener('click', e => {
                const player = that.getVideoComponent(e).querySelector('video');
                const rect = e.currentTarget.getBoundingClientRect();
                const clickX = e.clientX - rect.left;
                const progressBarWidth = rect.width;
                let percentage = clickX / progressBarWidth * 100;
                e.currentTarget.value = percentage;
                player.currentTime = player.duration * (percentage / 100);
            });

            this.player.addEventListener('timeupdate', e => {
                const player = e.currentTarget;
                const timeLabel = that.getVideoComponent(e).querySelector('.time');
                const progress = player.parentElement.querySelector('progress');
                const percentage = player.currentTime / player.duration * 100;

                let minutes = Math.floor(player.currentTime / 60);
                let seconds = Math.floor(player.currentTime - minutes * 60);
                let minuteValue;
                let secondValue;
                progress.value = percentage;
                if (minutes < 10) {
                    minuteValue = `0${ minutes}`;
                } else {
                    minuteValue = minutes;
                }

                if (seconds < 10) {
                    secondValue = `0${ seconds}`;
                } else {
                    secondValue = seconds;
                }

                let mediaTime = `${minuteValue }:${ secondValue}`;
                timeLabel.textContent = mediaTime;
                timeLabel.ariaLabel = mediaTime;
            });
        }
    }
}

export { Video };
