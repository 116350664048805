import AEM from 'base/js/aem';
import { Collapse } from 'bootstrap';

class Accordion extends AEM.Component {
    dataAttributes = {
        item: {
            expanded: 'data-cmp-expanded'
        },
        panel: {
            bsparent: 'data-bs-parent'
        }
    };

    init() {
        this.initAccordion();
    }

    /**
     * Initializes the Accordion.
     *
     * @private
     */
    initAccordion() {
        const el = this.element;
        const props = this.props;
        this.children = el.children;

        if (window.Granite && window.Granite.author && window.Granite.author.MessageChannel) {
            /*
             * Editor message handling:
             * - subscribe to "cmp.panelcontainer" message requests sent by the editor frame
             * - check that the message data panel container type is correct and that the id (path) matches this specific
             * - Accordion component if so, route the "navigate" operation to enact a navigation of the Accordion based
             * - on index data.
             */
            window.CQ = window.CQ || {};
            window.CQ.CoreComponents = window.CQ.CoreComponents || {};
            window.CQ.CoreComponents.MESSAGE_CHANNEL = window.CQ.CoreComponents.MESSAGE_CHANNEL ||
                new window.Granite.author.MessageChannel('cqauthor', window);

            window.CQ.CoreComponents.MESSAGE_CHANNEL.subscribeRequestMessage('cmp.panelcontainer', message => {
                if (message.data && message.data.type === 'bs-accordion' && message.data.id === props.cmpPanelcontainerId) {
                    if (message.data.operation === 'navigate') {
                        this.toggle(message.data.index);
                    }
                }
            });
        }

        if (el.classList.contains('accordion-float')) {
            const parent = el.parentNode;
            const accordionItems = el.querySelectorAll('.accordion-item');
            let compStyles = window.getComputedStyle(el);
            let accordionHeight = parseFloat(compStyles.height);

            // Calculate accordion height based on accordion button heights
            const handleResize = entries => {
                for (let entry of entries) {
                    let newHeight = 0;
                    let accordionButtons = entry.target.querySelectorAll('.accordion-button');

                    accordionButtons.forEach(item => {
                        let itemStyles = window.getComputedStyle(item);
                        let itemHeight = parseFloat(itemStyles.height);
                        newHeight += itemHeight;
                    });

                    if (accordionHeight !== newHeight) {
                        parent.style.height = `${newHeight}px`;
                        accordionHeight = newHeight;
                    }
                }
            };

            const resizeObserver = new ResizeObserver(handleResize);

            parent.style.height = `${accordionHeight}px`;
            resizeObserver.observe(el);

            accordionItems.forEach(item => {
                const collapsedElement = item.querySelector('.accordion-collapse');
                collapsedElement.addEventListener('show.bs.collapse', event => {
                    if (!el.hasAttribute('data-visible-within')) {
                        el.setAttribute('data-visible-within', 1);
                    } else {
                        let attValue = parseInt(el.getAttribute('data-visible-within'), 10);
                        el.setAttribute('data-visible-within', attValue + 1);
                    }

                    if (parseInt(el.getAttribute('data-visible-within'), 10) === 1) {
                        this.addZIndex(el);
                    }
                });

                collapsedElement.addEventListener('hidden.bs.collapse', () => {
                    let attValue = parseInt(el.getAttribute('data-visible-within'), 10);
                    if (attValue) {
                        el.setAttribute('data-visible-within', attValue - 1);
                    }

                    if (attValue === 1) {
                        this.removeZIndex(el);
                    }
                });
            });

            el.addEventListener('focusout', event => {
                if (!el.contains(event.relatedTarget)) {
                    const openItems = el.querySelectorAll('.accordion-collapse.show');
                    let elem = el;

                    openItems.forEach(item => {
                        let button = item.previousElementSibling.querySelector('.accordion-button');
                        if (item.classList.contains('show')) {
                            item.classList.remove('show');
                        }
                        button.classList.add('collapsed');
                        button.setAttribute('aria-expanded', 'true');
                    });
                    if (el.hasAttribute('data-visible-within')) {
                        el.removeAttribute('data-visible-within');
                    }
                    while (elem) {
                        elem.style.removeProperty('z-index');
                        elem = elem.parentElement;
                    }
                }
            });
        }
    }

    /**
     * Gets an item's expanded state from accordion settings.
     *
     * @private
     * @param {HTMLElement} item The item for checking its expanded state
     * @returns {Boolean} true if the item is expanded, false otherwise
     */
    getItemExpanded(item) {
        return item && item.dataset && item.dataset.cmpExpanded !== undefined;
    }

    /**
     * Returns TRUE if item is currently expanded.
     *
     * @param child
     * @returns {boolean}
     */
    checkIfItsExpanded(child) {
        return child.classList.contains('show');
    }

    /**
     * Toggles item panel.
     *
     * @private
     * @param {HTMLElement} panel The item to mark as expanded, or not expanded
     * @param {Boolean} expanded true to mark the item expanded, false otherwise
     */
    togglePanel(panel, expanded) {
        if (expanded) {
            panel.classList.add('show');
            // if element has data-bs-parent attribute, close the element (Single Expansion is set to on)
        } else {
            panel.classList.remove('show');
        }
    }

    /**
     * General handler for toggle of an item.
     *
     * @private
     * @param {Number} index The index of the item to toggle
     */
    toggle(index) {
        let item = this.children[index];
        let panel = item.querySelector('.accordion-collapse');
        let children = this.children;

        this.togglePanel(panel, !this.checkIfItsExpanded(panel));
        if (panel.hasAttribute(this.dataAttributes.panel.bsparent)) {
            this.closeUnselectedPanels(children, item);
        }
    }

    /**
     * Closes panels not being used or that are not open by default (in accordion settings).
     *
     * @param children
     * @param item
     */
    closeUnselectedPanels(children, item) {
        for (let i = 0; i < children.length; i++) {
            if (children[i] !== item) {
                let panel = children[i].querySelector('.accordion-collapse');
                let expanded = this.checkIfItsExpanded(panel);
                if (expanded) {
                    this.togglePanel(panel, false);
                }
            }
        }
    }

    addZIndex(element) {
        let elem = element;
        let z = 999;
        while (elem) {
            if (elem.classList.contains('cmp-container__content')) {
                elem.style.zIndex = z;
                z--;
            }
            elem = elem.parentElement;
        }

        element.style.zIndex = '1000';
    }
    removeZIndex(element) {
        let elem = element;
        while (elem) {
            if (elem.classList.contains('cmp-container__content')) {
                elem.style.removeProperty('z-index');
            }
            elem = elem.parentElement;
        }
        element.style.removeProperty('z-index');
    }
}

export { Accordion };
