import AEM from 'base/js/aem';

class Feedback extends AEM.Component {
    init() {
        const container = document.getElementById('feedback-container');
        const iframe = document.createElement('iframe');
        const path = window.location.pathname.split('/');
        let language = path[1] === 'content' ? path[4] : path[1];
        iframe.src = this.props.feedbackurl;
        iframe.scrolling = 'no';
        iframe.title = 'Feedback';
        container.appendChild(iframe);
    }
}

export { Feedback };
