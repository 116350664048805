function skipToMain() {
    const mainElement = document.querySelector('[role="main"]');
    if (mainElement) {
        mainElement.scrollIntoView();
    }
}
const skipToMainLink = document.getElementById('skipToMain');
if (skipToMainLink) {
    skipToMainLink.addEventListener('click', skipToMain);
}
