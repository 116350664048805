<template>
	<div class="card g-0 mb-3 border-0" v-for="event in filteredEventsData" :key="event.title">
		<div class="horzlayout event">
			<div class="horzimage">
				<a class="card-image-link" :href="event.elements.link.value"></a>
				<div class="card-image-top">
					<img class="img-fluid" v-bind:src="event.elements.image.value" v-bind:alt="event.title" />
				</div>
			</div>
			<div class="horzcontent event">
				<div class="card-body">
					<h2 class="card-title"><a class="card-heading-link" :href="event.elements.link.value">
						{{event.title}}</a>
					</h2>
					<div class="card-text">
						<time>{{formatDate(event.elements.startDate.value)}}</time>
						<time v-if="event.elements.endDate.value && event.elements.endDate.value !== event.elements.startDate.value"> –
							{{formatDate(event.elements.endDate.value)}}
						</time>
						<div v-html="event.elements.location.value"></div>
						<div v-html="event.elements.eventDescription.value"></div>
					</div>
					<div class="card-links">
						<a v-if="event.elements.link.value" class="card-link" v-bind:target="getTarget(event.elements.link.value)"
							 :href="event.elements.link.value">
              <span class="sr-only">{{ event.title }} </span>{{ eventDetails }}
							<i v-if="event.elements.link.value.includes(hostname) || !event.elements.link.value.includes('http')" class="fass fa-chevron-right" aria-hidden="true"></i>
							<i v-else class="fass fa-up-right-from-square" aria-hidden="true"></i>
							{{ link }}
						</a>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
import { inject } from 'vue';
let eventDetails = inject('eventDetails') || "Event Details";
const hostname = window.location.hostname;
const eventsData = JSON.parse(inject('events')).items;
const yesterday = new Date(Date.now());
yesterday.setDate(yesterday.getDate() - 1);
const filteredEventsData = eventsData.filter(item => ((new Date(item.elements.endDate.value) > yesterday) || item.elements.alwaysShow.value === 'true'));
const formatDate = ((date) => {
	const options = { weekday: 'short', month: 'short', day: 'numeric', year: 'numeric', timeZone: 'UTC' };
	const dateString = new Date(date).toLocaleDateString('en-US', options);
	return dateString;
});
const getTarget = (link => {
	if (link.includes(hostname) || !link.includes('http')) {
		return '_top';
	}
	else {
		return '_blank';
	}
})
</script>
